<template>
  <div>
    <b-row v-if="loading" align-h="center">
      <b-spinner
        variant="primary"
        type="grow"
        class="m-5"
        style="width: 5rem; height: 5rem;"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-card v-else>
      <b-row>
        <b-col>
          <b-form-group
            ref="image"
            label="Image"
            invalid-feedback="Image is required."
          >
            <b-media no-body>
              <div v-if="logoloading == true">
                <b-spinner
                  variant="primary"
                  type="grow"
                  class="m-5"
                  label="Spinning"
                ></b-spinner>
              </div>

              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                    @click="$refs.fileProfile.click()"
                    v-if="logoloading == false"
                    style="height: 150px; width: 150px; object-fit: cover"
                  />
                </b-link>

                <b-link v-if="logoloading == 'loaded'">
                  <div>
                    <i
                      @click="deleteLogo()"
                      style="margin-top: 70px; margin-left: 70px"
                      class="fa fa-trash fa-md text-danger position-absolute"
                    ></i>
                  </div>
                  <b-img
                    rounded
                    :src="plus"
                    style="height: 150px; width: 150px; object-fit: cover"
                  />
                  <br />
                </b-link>

                <input
                  type="file"
                  id="fileProfile"
                  hidden
                  ref="fileProfile"
                  @change="selectlogo()"
                  accept="image/*"
                />
              </b-media-aside>
            </b-media>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          <small class="text-muted">Recommended pixels are 500 x 500</small>
        </b-col>
      </b-row>

      <b-form-group label="Title" invalid-feedback="Title is required.">
        <b-form-input
          ref="name"
          placeholder="Enter Title here."
          required
          @focusout="CheckName()"
          v-model="myObj.title"
        />
      </b-form-group>

      <b-row>
        <b-col>
          <b-form-group label="Author" invalid-feedback="Author is required.">
            <b-form-input
              ref="author"
              placeholder="Enter author name here."
              required
              @focusout="CheckAuthor()"
              v-model="myObj.author"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Date"
            invalid-feedback="Select valid date."
            ref="date"
          >
            <flat-pickr
              v-model="myObj.date"
              class="form-control"
              placeholder="Select date."
              @on-change="checkDate()"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        label="Content"
        ref="content"
        invalid-feedback="Content is required."
      >
        <VueTrix v-model="myObj.content" placeholder="Enter content" />
      </b-form-group>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
          :disabled="request"
        >
          <b-spinner
            v-if="request"
            variant="light"
            type="grow"
            small
            label="Spinning"
          ></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BLink,
  BMediaAside,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import VueTrix from "vue-trix";

export default {
  components: {
    VueTrix,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BLink,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      myObj: {
        id: 0,
        title: "",
        content: "",
        date: null,
        author: "",
        image: "",
      },
      fileProfile: "",
      logoloading: false,
      plus: "",
      request: false,
      loading: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    LoadData() {
      if (this.$route.params.id > 0) {
        this.loading = true;

        var axios = require("axios");
        var config = {
          method: "get",
          url: "https://aafia.cloudious.net/api/News/" + this.$route.params.id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            // console.log(response);
            this.myObj = response.data.data;
            if (this.myObj.image) {
              this.logoloading = "loaded";
              this.plus = this.myObj.image;
            } else {
              this.logoloading = false;
              this.plus =
                "https://cdn.cloudious.net/file-1678189226453-406060527.png";
            }
            this.loading = false;
          })
          .catch(function(error) {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.myObj = {
          id: 0,
          title: "",
          content: "",
          date: null,
          author: "",
          image: "",
        };
        this.logoloading = false;
        this.plus =
          "https://cdn.cloudious.net/file-1678189226453-406060527.png";

        setTimeout(() => {
          const check = document.querySelector(
            'span[data-trix-button-group="file-tools"]'
          );
          // console.log(check);
          check.style.display = "none";
        }, 200);
      }
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];

      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.plus = e.data.myresp[0].path;

            this.myObj.image = this.plus;
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      this.myObj.image = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAuthor() {
      var elem = this.$refs["author"];
      if (this.myObj.author.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkContent() {
      var elem = this.$refs["content"];
      if (this.myObj.content.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDate() {
      var elem = this.$refs["date"];
      if (!this.myObj.date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    AddFlavour() {
      this.CheckImage();
      this.CheckName();
      this.CheckAuthor();
      this.checkDate();
      this.checkContent();
      if (
        this.CheckImage() == false ||
        this.CheckName() == false ||
        this.checkDate() == false ||
        this.CheckAuthor() == false ||
        this.checkContent() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        if (this.myObj.id == 0) {
          // console.log("Obj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://aafia.cloudious.net/api/News",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                // console.log(response.data);
                Swal.fire("Success!", "News added.", "success").then((res) => {
                  this.request = false;
                  this.$router.replace({ name: "apps-news" });
                });
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        } else {
          //Edit
          // console.log("edit_Obj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://aafia.cloudious.net/api/News/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              //   console.log(response.data);
              if (response.data.status === "success") {
                this.request = false;

                this.$router.replace({ name: "apps-news" }).then(() => {
                  this.$bvToast.toast("News updated.", {
                    title: "Success!",
                    variant: "success",
                    toaster: "b-toaster-top-center",
                  });
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style></style>
